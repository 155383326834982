<template>
  <div class="list-card-add__actions">
    <button class="button button--primary" @click="showModal = true">
      {{ product.contributionAmount == 0 ? $t("generic.gift") : $t("guest.contribution.contributeToThis") }}
    </button>
    <modal-dialog
      v-if="modalStep == 1"
      :show="showModal"
      :product="product"
      target-class="modal__card--mini"
      @close="showModal = false"
    >
      <div class="modal-regalo">
        <h4 class="modal-regalo__titulo">{{ $t("guest.contribution.title") }}</h4>
        <div class="modal-regalo__product">
          <article class="mrp">
            <div class="mrp__image">
              <div class="mrp__cover">
                <img :src="$getImgUrl(product.photo)" :alt="product.name" />
              </div>
            </div>
            <div class="mrp__content">
              <div class="mrp__titulo">
                <mq-layout :mq="['m480', 'm576']">
                  {{ $utils.truncate(product.name, 55) }}
                </mq-layout>
                <mq-layout mq="m768+">
                  {{ $utils.truncate(product.name, 95) }}
                </mq-layout>
              </div>
              <div class="mrp__marca">{{ product.brand }}</div>
              <div class="mrp__price">
                {{  $utils.formatMoney(product.price) }}
                <div
                  class="mrp__tooltip"
                  v-show="!product.isFree && product.productRetailers.length > 1"
                  v-tooltip="{
                    html: true,
                    content: 'El precio final depende de la tienda en la que se compre.',
                    handleReside: false,
                    placement: 'bottom-start',
                    triggers: ['hover', 'focus', 'click'],
                  }"
                >
                  <i class="uil uil-info-circle"></i>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="modal-regalo__importe">
          <section class="mri">
            <div class="mri__titulo">
              {{ $t("guest.contribution.chooseAmount", { name: myList.ownerName }) }}
            </div>

            <div class="mri__aportado" v-if="product.contributionAmount > 0">
              <span class="badge badge--light badge--sm">
                {{ $t("guest.contribution.contributedSoFar") }}
                <strong>{{ product.contributionAmount }} € </strong> ({{ $t("guest.contribution.miss").toLowerCase() }}
                {{ (product.contributionGoalAmount - product.contributionAmount).toFixed(2) }} €)
              </span>
            </div>

            <div class="mri__range" v-if="productPrice > 30">
              <range-input
                :min="1"
                v-model="rangeValue"
                :max="round(productPrice)"
                :productPrice="productPrice"
                showButtons="true"
                :initialInputValue="rangeValue"
              />
            </div>

            <div
              class="mri__actions"
              :style="{ paddingTop: productPrice <= 30 ? '1.5rem' : '0' }"
            >
              <bbButton
                :label="$t('guest.contribution.contributeWith', { amount: $utils.formatMoney(rangeValue) })"
                class="button--block"
                @click="GoToSetep2()"
              ></bbButton>
            </div>
          </section>
        </div>
        <div class="modal-regalo__retailers">
          <!-- LINK EXTERNO APORTACIONES -->
          <section class="mrt">
            <div class="mrt__title">{{ $t("guest.contribution.also") }}</div>
            <div class="mrt__items" v-if="!product.isFree">
              <div
                class="mrt__item"
                v-for="(item, index) in product.productRetailers"
                :key="index"
                @click="openBuy(item)"
              >
                <div class="mrt__logo">
                  <img :src="$getLogoRetalier(item.name)" alt="" />
                </div>
                <div class="mrt__content">
                  <div class="mrt__retailer">
                    <div class="mrt__name">{{ item.name }}</div>
                    <div class="mrt__price">({{  $utils.formatMoney(item.price) }})</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <a class="mrt__item" @click.stop="openBuy({ 'name': product.retailerName, 'price': product.price })">
                <div class="mrt__logo">
                  <img :src="$getLogoRetalier(product.retailerName)" alt="" />
                </div>
                <div class="mrt__content">
                  <div class="mrt__retailer">
                    <div class="mrt__name">{{ product.retailerName }}</div>
                    <div class="mrt__price">({{ product.price }} €)</div>
                  </div>
                </div>
              </a>
            </div>
            <transition name="bbFade" @after-enter="showBuyContent = true">
                <div class="modal" v-if="showBuy" @click="showBuyContent = false">
                  <transition name="bbUp" @after-leave="closeBuy()">
                    <div v-show="showBuyContent" class="modal__card" @click.stop="">
                      <div class="modal__dialog">
                        <div class="modal__header">
                          <div class="modal__back" v-show="buyStep > 1">
                            <img
                              @click="buyStep--"
                              src="../../../assets/img/ico/ico-arrow-back.svg"
                              alt="Atras"
                            />
                          </div>
                          <div class="modal__title">
                            <slot name="titulo"></slot>
                          </div>
                          <div class="modal__close">
                            <img
                              src="../../../assets/img/ico/ico-close.svg"
                              alt="Cerrar"
                              @click="showBuyContent = false"
                            />
                          </div>
                        </div>
                        <div class="modal__content" ref="scroll_container">
                          <FluxRegaloStep1
                            v-if="buyStep === 1"
                            :product="product"
                            :list="list"
                            :retailer="retailer"
                            @next="nextFirstStepBuy"
                          ></FluxRegaloStep1>
                          <FluxRegaloStep2
                            v-if="buyStep === 2"
                            :product="product"
                            :list="list"
                            :retailer="retailer"
                          ></FluxRegaloStep2>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </transition>
          </section>
        </div>
      </div>
    </modal-dialog>
    <modal-dialog
      v-else
      :show="showModal"
      target-class="modal__card--mini"
      @close="closeModal()"
    >
      <div class="modal-regalo-resumen">
        <h4 class="modal-regalo-resumen__titulo">{{ $t("guest.contribution.title") }}</h4>
        <div class="modal-regalo-resumen__subtitulo">
          {{ $t("guest.contribution.summarySubTitle", { name: list.ownerName }) }}
        </div>
        <div class="modal-regalo-resumen__card">
          <div class="rrc">
            <div class="rrc__cols">
              <div class="rrc__cover">
                <img :src="$getImgUrl(product.photo)" :alt="product.name" />
              </div>
              <div class="rrc__summary">
                <div class="rrc__titulo">{{ product.name.slice(0, 40) + "..." }}</div>
                <div class="rrc__marca">{{ product.brand }}</div>
                <dl class="rrc__resume">
                  <div class="rrc__resume-item">
                    <dt>{{ $t("guest.contribution.yourContribution") }}</dt>
                    <dd>{{ (Math.round(rangeValue * 100) / 100).toFixed(2) }} €</dd>
                  </div>
                  <div class="rrc__resume-item" v-if="serviceFee">
                    <dt>{{ $t("guest.contribution.serviceFee") }}*</dt>
                    <dd>{{ (Math.round(serviceFee * 100) / 100).toFixed(2) }} €</dd>
                  </div>
                  <div class="rrc__resume-item rrc__resume-item--total" v-if="serviceFee">
                    <dt>{{ $t("guest.contribution.total") }}</dt>
                    <dd>{{ totalPayment.toFixed(2) }} €</dd>
                  </div>
                </dl>
              </div>
            </div>
            <div class="rrc__notice" v-if="serviceFee">
              {{ $t("guest.contribution.feeExplanation") }}
            </div>
          </div>
        </div>
        <div class="modal-regalo-resumen__checkout">
          <div class="modal-regalo-resumen__checkout-title">{{ $t("guest.contribution.payment") }}</div>
          <div class="modal-regalo-resumen__checkout-subtitle">
            {{ $t("guest.contribution.paymentSecurityNote") }}
          </div>

          <div class="modal-regalo-resumen__stripe">
            <!-- Aqui stripe -->
          </div>
        </div>
        <div class="modal-regalo-resumen__actions">
          <bbButton
            :label='$t("guest.contribution.contribute")'
            class="button--block"
            :loading="payLoading"
            @click="pay()"
          ></bbButton>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog";
import { VTooltip } from "floating-vue";
import { mapGetters } from "vuex";
import RangeInput from "./RangeInput.vue";
import bbButton from "../../../components/buttons/bbButton.vue";
import FluxRegaloReservaStep1 from "./FluxRegaloReservaStep1.vue";
import FluxRegaloReservaStep2 from "./FluxRegaloReservaStep2.vue";
import FluxRegaloStep2 from "./FluxRegaloStep2.vue";
import FluxRegaloStep1 from "./FluxRegaloStep1.vue";
import ServiceFee from "../../../Mixins/ServiceFee";
import { FEES_SUPPORTED_BY } from "../../../constants/contributions-configuration";

export default {
  name: "ListProductRegalar",
  mixins: [
    ServiceFee,
  ],
  components: {
    FluxRegaloStep1,
    FluxRegaloStep2,
    FluxRegaloReservaStep2,
    FluxRegaloReservaStep1,
    ModalDialog,
    RangeInput,
    bbButton,
  },
  data() {
    return {
      loadingCancelBooking: false,
      retailer: null,
      showBuy: false,
      showBuyContent: false,
      showBooking: false,
      showBookingContent: false,
      bookingStep: 1,
      buyStep: 1,
      showCancel: false,
      showCancelContent: false,
      showAlreadyBought: false,
      showAlreadyBoughtContent: false,
      showModal: false,
      rangeValue: 30,
      swidth: null,
      rangeSize: null,
      shortcuts: [
        {
          value: 10,
        },
        {
          value: 20,
        },
        {
          value: 30,
        },
      ],
      maxPrice: 0,
      productPrice: 0,
      modalStep: 1,
      payLoading: false,
    };
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      retailers: "retailers",
      myList: "myList",
      contributionsConfigurationFeesSupportedBy: "contributionsConfigurationFeesSupportedBy",
    }),
    retailersId() {
      return "product_" + this.product.id + "_retailers";
    },
    serviceFee() {
      if (this.contributionsConfigurationFeesSupportedBy === FEES_SUPPORTED_BY.owner) {
        return 0;
      }

      return this.getServiceFee(this.rangeValue);
    },
    paymentAmount() {
      //return Math.round((parseFloat(this.rangeValue) + this.serviceFee) * 100) / 100
      return Math.round(parseFloat(this.rangeValue) * 100) / 100;
    },
    totalPayment() {
      return Math.round((parseFloat(this.rangeValue) + this.serviceFee) * 100) / 100;
    },
  },
  methods: {
    round(value) {
      //round to integer, the next value
      return Math.ceil(value);
    },
    closeModal() {
      this.showModal = false;
      this.modalStep = 1;
    },
    async pay() {
      window.mixpanel.track("Aportar 2", {
        "Item name": this.product.name,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Contribution amount": this.rangeValue,
      });
      this.payLoading = true;
      let data = {
        accountId: this.list.stripeAccountId,
        amount: this.paymentAmount * 100,
        applicationFeeAmount: this.serviceFee * 100,
        productName: this.product.name,
        productImage: this.$getImgUrl(this.product.photo),
        productType: this.product.isFree ? "free" : "catalogue",
        productId: this.product.isFree ? this.product.id : this.product.listProductId,
        listId: this.list.id,
      };
      if (this.$route.name == "guestProduct") {
        data.callbackPath = this.$route.path + "?success=true";
        data.callbackCancelledPath = this.$route.path + "?success=false";
      }
      try {
        var res = await this.$store.dispatch("createCheckoutSession", data);
        let paymentUrl = res.data.url;
        //navigate
        window.open(paymentUrl, "_self");
      } catch (error) {
        console.log(error);
      }
    },

    openBooking() {
      document.body.classList.add("modal-open");
      this.showBooking = true;
    },
    resetScroll() {
      this.$refs.scroll_container.scrollTop = 0;
    },
    nextFirstStepBuy() {
      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Redireccionar a retailer", {
        "Object name": this.product.name,
        "Selected Vendor": this.retailer?.name,
        "Object price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Buyer role": "Convidat",
        Src: "fitxa del regalador",
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item Retailers": this.productRetailersName,
      });
      this.$emit("update");
      this.buyStep++;
      this.resetScroll();
    },
    nextFirstStep() {
      this.$emit("update");
      this.bookingStep++;
    },
    finishBooking() {
      this.showBookingContent = false;
      document.body.classList.remove("modal-open");
      this.bookingStep = 1;
      this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
    closeBooking() {
      this.showBooking = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
      if (this.bookingStep === 2)
        this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
    closeAlreadyBought() {
      this.showAlreadyBought = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
    },
    closeCancel() {
      this.showCancel = false;
      document.body.classList.remove("modal-open");
      this.$emit("close");
    },
    closeBuy() {
      this.showBuy = false;
      document.body.classList.remove("modal-open");
      this.buyStep = 1;
      this.$emit("close");
    },
    openBuy(pProductRetailer) {
      console.log(pProductRetailer)
      document.body.classList.add("modal-open");
      this.showBuy = true;
      this.retailer = pProductRetailer;
      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Comprar", {
        "Object name": this.product.name,
        "Retailer name": this.retailer?.name,
        "Selected Vendor": this.retailer?.name,
        "Object price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Buyer role": "Convidat",
        Src: "fitxa del regalador",
        "Sold by HelloBB": this.SoldByHelloBB,
        "Item Retailers": this.productRetailersName,
      });
    },
    GoToSetep2() {
      this.modalStep = 2;
      window.mixpanel.identify(this.myList.userId);
      window.mixpanel.track("Aportar 1", {
        "Item name": this.product.name,
        "Item price": this.product.price,
        "List URL": "https://www.hellobb.net/guest/" + this.myList.id,
        "Contribution amount": this.rangeValue,
        "Contribution max": this.productPrice,
      });
    },
  },
  mounted() {
    //this.maxPrice = this.product.contributionAmount == 0 ? this.product.price : this.product.contributionGoalAmount - this.product.contributionAmount
    this.productPrice =
      this.product.contributionAmount == 0
        ? this.product.price
        : this.product.contributionGoalAmount - this.product.contributionAmount;
    this.rangeValue =
      this.product.contributionAmount == 0
        ? this.product.price
        : this.product.contributionGoalAmount - this.product.contributionAmount;
    //round rangeValue to decimal with 2 digits
    this.rangeValue = Math.round(this.rangeValue * 100) / 100;
    this.shortcuts[0] = this.product.price * 0.25;
    this.shortcuts[1] = this.product.price * 0.5;
    this.shortcuts[2] = this.product.price * 0.75;
  },
  // created() {
  //   window.addeventlistener("resize", this.screenwidth);
  // },
  // mounted() {
  //   this.screenwidth();
  //   this.updateRangeSize();
  //   this.$nextTick(() => {
  //     this.updateRangeSize();
  //     window.addEventListener("resize", this.updateRangeSize);
  //   });
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.screenwidth);
  // },
  // methods: {
  //   screenwidth() {
  //     this.swidth = this.$refs.container?.offsetwidth;
  //   },
  //   updateRangeSize() {
  //     const rangeInput = document.getElementById("range");
  //     if (rangeInput) {
  //       this.rangeSize = rangeInput.offsetWidth;
  //     }
  //   },
  // },
};
</script>
